import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import SectionWithSliderCentered from '../common/SectionWithSliderCentered/SectionWithSliderCentered';
import { FreeProgramModel } from '../../models';

import WebinarItem from './WebinarItem';

interface WebinarItemsProps {
    items: FreeProgramModel[];
}

const Container = styled.div`
    margin-top: -50px;
    margin-bottom: -20px;
`;

const WebinarItems: React.FC<WebinarItemsProps> = ({ items }) => {
    return (
        <Container>
            <SectionWithSliderCentered<FreeProgramModel>
                items={items}
                sliderElement={WebinarItem}
                sliderId="webinars_section"
                sliderOptions={{
                    gap: -70,
                }}
                glideButtonsStyles={{
                    bottom: '36px',
                }}
                withShadows
            />
        </Container>
    );
};

export default WebinarItems;
