import { useStaticQuery, graphql } from 'gatsby';

import { FreeProgramModel } from '../models';

export const useFreeWebinars = (): FreeProgramModel[] => {
    const { allWpFreeProgram } = useStaticQuery(
        graphql`
            {
                allWpFreeProgram(
                    filter: { free_program_acf: { type: { eq: "vebinar" } } }
                    sort: { fields: free_program_acf___data }
                ) {
                    nodes {
                        acf: free_program_acf {
                            description
                            link
                            subTitle: subtitle
                            title
                            type
                            freeProgramImage: freeprogramimage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 500)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const nodes = allWpFreeProgram?.nodes;

    return nodes?.map((item) => ({ ...item?.acf })) || [];
};
