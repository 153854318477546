import React from 'react';

import { FreeProgramModel } from '../../models';
import SectionWithSliderCentered from '../common/SectionWithSliderCentered/SectionWithSliderCentered';
import { CONTENT_WIDTH } from '../../constants';

import MaterialsItem from './MaterialsItem';

interface MaterialsListProps {
    items: FreeProgramModel[];
}

const MaterialsList: React.FC<MaterialsListProps> = ({ items }) => {
    return (
        <SectionWithSliderCentered<FreeProgramModel>
            items={items}
            sliderElement={MaterialsItem}
            sliderId="free_materials"
            maxWidth={CONTENT_WIDTH}
            sliderOptions={{
                perView: 3,
                gap: 16,
            }}
        />
    );
};

export default MaterialsList;
