import React from 'react';

import { FreeMasterClassModel } from '../../models';
import SectionWithSliderCentered from '../common/SectionWithSliderCentered/SectionWithSliderCentered';
import { CONTENT_WIDTH } from '../../constants';

import MasterClassItem from './MasterClassItem';

interface MasterClassListProps {
    items: FreeMasterClassModel[];
}

const MasterClassList: React.FC<MasterClassListProps> = ({ items }) => {
    return (
        <SectionWithSliderCentered<FreeMasterClassModel>
            items={items}
            sliderElement={MasterClassItem}
            sliderId="free_master_class"
            maxWidth={CONTENT_WIDTH}
            sliderOptions={{
                perView: items.length === 2 ? 2 : 3,
                gap: 16,
            }}
        />
    );
};

export default MasterClassList;
