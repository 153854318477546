import React from 'react';
import styled from '@emotion/styled';

import { FreeMasterClassModel } from '../../models';
import {
    PointBlock,
    RichTextView,
    RoundedButton,
    FlexSpacer,
} from '../commonStyled';
import { FONT_GOTHAM_LIGHT, FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { CompatibleImg } from '../Img';

interface MasterClassItemProps {
    item: FreeMasterClassModel;
}

const MasterClassItem: React.FC<MasterClassItemProps> = ({
    item: {
        date,
        button_description,
        button_text,
        date_text,
        subTitle,
        description,
        freeMasterclassImage = {},
        link,
        title,
        type,
    },
}) => {
    return (
        <PointBlockStyled>
            <MasteClassType dangerouslySetInnerHTML={{ __html: type }} />
            <TitleContainer>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
                <Subtitle dangerouslySetInnerHTML={{ __html: subTitle }} />
            </TitleContainer>
            {freeMasterclassImage && (
                <Image>
                    <CompatibleImg image={freeMasterclassImage?.localFile} />
                </Image>
            )}
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <FlexSpacer />
            {date_text ? (
                <Date dangerouslySetInnerHTML={{ __html: date_text }} />
            ) : (
                <Date>{date} (МСК)</Date>
            )}
            <RoundedButtonStyled href={link} target="_blank" rel="nofollow">
                {button_text || 'Принять участие'}
            </RoundedButtonStyled>
            {button_description && (
                <ButtonDescription>{button_description}</ButtonDescription>
            )}
        </PointBlockStyled>
    );
};

const Subtitle = styled.div`
    color: #000000;
    font-size: 18px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    line-height: 24px;
    text-align: center;
`;

const MasteClassType = styled.div`
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 16px;
`;

const TitleContainer = styled.div`
    /* min-height: 118px; */
    margin-bottom: 30px;
`;

const Title = styled.div`
    color: #a60424;
    font-size: 25px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    line-height: 30px;
    text-align: center;
    margin-bottom: 18px;
`;

const Image = styled.div`
    height: auto;
    width: 140px;
    margin-bottom: 37px;
`;

const Description = styled(RichTextView)`
    font-family: ${FONT_GOTHAM_LIGHT};
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 34px;
`;

const Date = styled.div`
    color: #a60424;
    font-size: 24px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 45px;
    padding: 0 22px;
`;

const ButtonDescription = styled.div`
    color: #a60424;
    font-size: 18px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: -24px;
    margin-top: 6px;
`;

const RoundedButtonStyled = styled(RoundedButton)`
    color: #a60424;
`;

const PointBlockStyled = styled(PointBlock)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 24px 20px 51px 20px;
    max-width: none;
    flex-basis: 356px;
    margin: 0 auto;
    width: 400px;
`;

export default MasterClassItem;
