import { useStaticQuery, graphql } from 'gatsby';

import { FreeProgramModel } from '../models';

export const useFreeTests = (): FreeProgramModel[] => {
    const { allWpFreeProgram, allWpInternalTest } = useStaticQuery(
        graphql`
            {
                allWpFreeProgram(
                    filter: { free_program_acf: { type: { eq: "test" } } }
                ) {
                    nodes {
                        free_program_acf {
                            description
                            link
                            subTitle: subtitle
                            title
                            type
                            freeProgramImage: freeprogramimage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 500)
                                    }
                                }
                            }
                        }
                    }
                }
                allWpInternalTest {
                    nodes {
                        internal_tests_acf {
                            testCardImage: testcardimage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 500)
                                    }
                                }
                            }
                        }
                        slug
                        title
                    }
                }
            }
        `
    );

    const nodes: FreeProgramModel[] =
        allWpFreeProgram?.nodes?.map((item) => ({
            ...item?.free_program_acf,
        })) || [];

    const nodesTest: FreeProgramModel[] =
        allWpInternalTest?.nodes?.map((x) => ({
            title: x.title,
            link: `/${x.slug}`,
            freeProgramImage: x.internal_tests_acf.testCardImage,
            type: { label: 'Тест' },
        })) || [];

    return [...nodesTest, ...nodes];
};
