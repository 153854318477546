import React from 'react';
import styled from '@emotion/styled';

import { FreeProgramModel } from '../../models';
import { RichTextView, RoundedButtonWithArrow, device } from '../commonStyled';
import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';

interface WebinarItemProps {
    item: FreeProgramModel;
}

const WebinarItem: React.FC<WebinarItemProps> = ({
    item: { link, title, type, description },
}) => {
    return (
        <Container target="_blank" rel="nofollow" href={link}>
            <MasteClassType>{type.label}</MasteClassType>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <RoundedButtonStyled href={link} target="_blank" rel="nofollow">
                Записаться
            </RoundedButtonStyled>
        </Container>
    );
};

const MasteClassType = styled.div`
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 30px;
`;

const RoundedButtonStyled = styled(RoundedButtonWithArrow)``;

const Title = styled.div`
    color: #000000;
    font-size: 25px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    margin-bottom: 28px;
    min-height: 56px;
`;

const Description = styled(RichTextView)`
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 34px;
`;

const Container = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    text-decoration: none;
    padding: 28px 40px 49px 40px;
    box-shadow: 9px 7px 44px 0 #d8cece;
    background-color: #ffffff;
    border-radius: 33px;
    margin: 57px 57px 73px 73px;

    @media ${device.mobileMedium} {
        margin: 57px 24px 73px 24px;
    }

    &:hover {
        color: #000;
    }
`;

export default WebinarItem;
