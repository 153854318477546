import React from 'react';

import SectionWithSliderCentered from '../common/SectionWithSliderCentered/SectionWithSliderCentered';
import { FreeProgramModel } from '../../models';
import { CONTENT_WIDTH } from '../../constants';

import TestItem from './TestItem';

interface TestItemsProps {
    items: FreeProgramModel[];
}

const TestItems: React.FC<TestItemsProps> = ({ items }) => {
    return (
        <SectionWithSliderCentered<FreeProgramModel>
            items={items}
            sliderElement={TestItem}
            sliderId="tests_section"
        />
    );
};

export default TestItems;
