import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import { ContentMain, RichTextView } from '../components/commonStyled';
import Sections from '../components/Sections';
import SectionDevider from '../components/SectionDevider';
import SectionTitle from '../components/SectionTitle';
import MasterClassList from '../components/MasterClassList/MasterClassList';
import { useFreeMasterClasses } from '../queries/useFreeMasterClasses';
import TestItems from '../components/TestItems/TestItems';
import WebinarItems from '../components/Webinars/WebinarItems';
import { useFreeTests } from '../queries/useFreeTests';
import { useFreeWebinars } from '../queries/useFreeWebinars';
import { useFreeMaterials } from '../queries/useFreeMaterials';
import MaterialsList from '../components/Materials/MaterialsList';

export default function BesplatnyeProgrammy({ data, location }) {
    const {
        title,
        seo,
        free_program_page_acf: {
            descritptionMasterClasses,
            titleTests,
            descriptionTests,
            titleWebinars,
            descriptionWebinars,
            titleMaterials,
        },
        common_acf: { blocks },
    } = data?.wpPage;

    const masterClassItems = useFreeMasterClasses();
    const tests = useFreeTests();
    const webinars = useFreeWebinars();
    const materials = useFreeMaterials();

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <TextContainer>
                    <RichTextView
                        fontSize={18}
                        dangerouslySetInnerHTML={{
                            __html: descritptionMasterClasses,
                        }}
                    />
                </TextContainer>
            </ContentMain>
            <MasterClassList items={masterClassItems} />
            <SectionDevider />
            <SectionTitleStyled title={titleTests} />
            <ContentMain>
                <TextContainer>
                    <RichTextView
                        fontSize={18}
                        dangerouslySetInnerHTML={{ __html: descriptionTests }}
                    />
                </TextContainer>
            </ContentMain>
            <TestItems items={tests} />
            <SectionDevider />
            <SectionTitleStyled title={titleWebinars} />
            <ContentMain>
                <TextContainer>
                    <RichTextView
                        fontSize={18}
                        dangerouslySetInnerHTML={{
                            __html: descriptionWebinars,
                        }}
                    />
                </TextContainer>
            </ContentMain>
            <WebinarsContainer>
                <WebinarItems items={webinars} />
            </WebinarsContainer>
            <MaterialsContainer>
                <SectionDevider />
                <SectionTitleStyled title={titleMaterials} />
                <MaterialsList items={materials} />
            </MaterialsContainer>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: { whiteStyle: true, noButton: true },
                }}
            />
        </PageLayout>
    );
}

const WebinarsContainer = styled('div')`
    margin-bottom: 60px;
`;

const SectionTitleStyled = styled(SectionTitle)`
    margin-bottom: 36px;
`;

const MaterialsContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f7f0ed;
`;

const TextContainer = styled.div`
    padding-bottom: 24px;
`;

export const pageQuery = graphql`
    query FreePrograms {
        wpPage(common_acf: { id: { eq: "freePrograms" } }) {
            title
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
            free_program_page_acf {
                descritptionMasterClasses: descritptionmasterclasses
                titleTests: titletests
                descriptionTests: descriptiontests
                titleWebinars: titlewebinars
                descriptionWebinars: descriptionwebinars
                titleMaterials: titlematerials
            }
        }
    }
`;
