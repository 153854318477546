import React from 'react';
import styled from '@emotion/styled';

import { FreeProgramModel } from '../../models';
import { FlexSpacer, device, RoundedButtonWithArrow } from '../commonStyled';
import CourseCard from '../CourseCard';

interface TestItemProps {
    item: FreeProgramModel;
}

const TestItem: React.FC<TestItemProps> = ({
    item: { freeProgramImage, link, title, subTitle, type },
}) => {
    return (
        <Container target="_blank" rel="nofollow" href={link}>
            <ItemCard
                image={freeProgramImage}
                title={title}
                subTitle={subTitle}
                cardType={type.label}
                fontSize={24}
            />
            <FlexSpacer />
            <RoundedButtonWithArrow as="div">
                Пройти тест
            </RoundedButtonWithArrow>
        </Container>
    );
};

const ItemCard = styled(CourseCard)`
    margin-bottom: 40px;
`;

const Container = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    text-decoration: none;
    margin: 0 25px;

    &:hover {
        color: #000;
    }
`;

export default TestItem;
