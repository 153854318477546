import React from 'react';
import styled from '@emotion/styled';

import { FreeProgramModel } from '../../models';
import { FlexSpacer } from '../commonStyled';
import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { CompatibleImg } from '../Img';

interface MaterialsItemProps {
    item: FreeProgramModel;
}

const MaterialsItem: React.FC<MaterialsItemProps> = ({
    item: { freeProgramImage, link, title, subTitle },
}) => {
    return (
        <Container href={link} target="_blank" rel="nofollow">
            <Image>
                <CompatibleImg image={freeProgramImage.localFile} />
            </Image>
            <FlexSpacer />
            <MaterialsType>{subTitle}</MaterialsType>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
        </Container>
    );
};

const MaterialsType = styled.div`
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 10px;
`;

const Title = styled.div`
    color: #000000;
    font-size: 25px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
    min-height: 118px;
`;

const Image = styled.div`
    width: 220px;
    margin-bottom: 17px;
`;

const Container = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

export default MaterialsItem;
