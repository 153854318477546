import { useStaticQuery, graphql } from 'gatsby';

import { FreeMasterClassModel } from '../models';

export const useFreeMasterClasses = (): FreeMasterClassModel[] => {
    const { allWpFreeMasterclass } = useStaticQuery(
        graphql`
            {
                allWpFreeMasterclass(
                    sort: { fields: free_masterclasses_afc___date }
                ) {
                    nodes {
                        free_masterclasses_afc {
                            date(
                                formatString: "DD MMMM YYYY HH:mm"
                                locale: "RU"
                            )
                            description
                            link
                            subTitle: subtitle
                            title
                            type
                            date_text: dateText
                            button_text: buttonText
                            button_description: buttonDescription
                            freeMasterclassImage: freemasterclassimage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 200)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const nodes = allWpFreeMasterclass?.nodes;

    return nodes?.map((item) => ({ ...item?.free_masterclasses_afc })) || [];
};
